function ObjectActionComponent(element) {
    WebPageComponent.call(this, element);

    this.attachRefreshHandler = function() {
        var object = this;
        var query = new DomQuery(this.element);
        var item = query.getDescendant(WithClass("Refresh"));

        if (item !== null)
            item.addEventListener("click", function(event) { object.refresh(); });
    }

    this.determineElements = function() {
        var query = new DomQuery(this.element);

        this.toolbar = new Toolbar(query.getDescendant(WithClass("Toolbar")));
        this.toolbar.parentComponent = this;

        this.content = this.element.childNodes[1];
    }

    this.fireDataChanged = function() {
        distributeEvent(new DataChangedEvent(this));
    }

    this.getForm = function() {
        return this.content;
    }

    this.handleEvent = function(event) {
        if (event instanceof DataChangedEvent && this.refreshForSiblings)
            this.refresh();
    }

    this.handleHttpCommandResponse = function(xmlResponse) {
        var contentType = xmlResponse.getResponseHeader('Content-Type');

        if (contentType.slice(0, 15) == "application/xml") {

            var response = xmlResponse.responseXML;
            var query = new DomQuery(response.documentElement);

            var updateScopeElement = query.getChild(WithTagName("UpdateScope"));
            var updateScope = RenderScope.Complete;

            if (updateScopeElement !== null)
                updateScope = RenderScope.fromText(updateScopeElement.getAttribute("Value"));

            this.sendRefreshRequest(updateScope);
        }

        this.updatingCounter.decrease();
    }

    this.initialize = function() {
        this.uri = this.element.dataset.Uri;
        this.refreshForSiblings = this.element.dataset.RefreshForSiblings === "true";
    };

    this.refresh = function() {
        this.sendCommandRequest("<Refresh/>");
    }

    this.refreshWithScope = function(xmlResponse, scope) {
        var dummyElement = document.createElement("div");

        if (scope === RenderScope.Toolbar) {
            dummyElement.innerHTML = xmlResponse.responseText;

            var newToolbar = dummyElement.firstChild;
            this.toolbar.replace(newToolbar, true);

            this.attachRefreshHandler();
        }
        else {
            dummyElement.innerHTML = xmlResponse.responseText;
            dummyElement = dummyElement.firstChild;

            var newToolbar = dummyElement.childNodes[0];
            var newContent = dummyElement.childNodes[1];

            interactivityRegistration.detach(this.content);
            this.element.replaceChild(newContent, this.content);
            this.content = newContent;
            interactivityRegistration.attach(this.content);

            this.toolbar.replace(newToolbar, false);
            this.attachRefreshHandler();
        }

        this.updatingCounter.decrease();
    }

    this.refreshFromResponse = function(xmlResponse) {
        this.refreshWithScope(xmlResponse, RenderScope.Complete);
    }

    this.sendActionRequest = function(action, form, callback) {
        const xmlRequest = newXmlRequest();

        let formData;

        if (form !== null)
            formData = new FormData(form);
        else
            formData = new FormData();

        formData.append("Action", action);
        this.updatingCounter.increase();

        xmlRequest.open("POST", this.uri, true);
        xmlRequest.setRequestHeader("Accept", "text/html;q=1, */*;q=0.9")
        xmlRequest.onreadystatechange = () => {
            if (xmlRequest.readyState == 4) {
                if (xmlRequest.status === 200) {
                    const contentType = xmlRequest.getResponseHeader("Content-Type");

                    if (contentType.slice(0, 9) == "text/html") {
                        this.toolbar.state = ToolbarState.Warning;
                        this.refreshFromResponse(xmlRequest);
                        this.fireDataChanged();
                    }
                    else if (contentType.slice(0, 15) == "application/xml") {
                        const response = xmlRequest.responseXML;

                        if (response.documentElement.hasAttribute("Location")) {
                            const response = xmlRequest.responseXML;
                            const type = response.documentElement.getAttribute("Type");
                            const redirect = response.documentElement.getAttribute("Location");

                            if (type == "Download") {
                                window.open(redirect);
                                this.updatingCounter.decrease();
                                this.sendCommandRequest("<Action Name=\"\"/>");;
                            }
                            else if (type == "Redirect") {
                                application.pageHandler.load(redirect);
                            }
                        }
                        else {
                            this.handleHttpCommandResponse(xmlRequest);
                            this.fireDataChanged();

                            application.toastBox.addMessage(new ToastMessage("Successful", "Action submitted successfully", "Successful"));
                        }
                    }
                }
                else if (xmlRequest.status === 409 || xmlRequest.status === 500) {
                    const division = document.createElement("div");
                    division.innerHTML = xmlRequest.responseText;

                    const error = new DomQuery(division).getDescendant(WithClass("Error"));
                    application.toastBox.addMessage(new ToastMessage("Error", error.innerHTML, "Error"));

                    this.updatingCounter.decrease();
                }

                callback();
            }
        };

        xmlRequest.send(formData);
    }

    this.sendCommandRequest = function(command) {
        var object = this;

        this.updatingCounter.increase();

        this.client.sendXmlRequest(
            this.uri,
            "<Commands>" + command + "</Commands>",
            function(xmlResponse) { object.handleHttpCommandResponse(xmlResponse); }
        );
    }

    this.sendRefreshRequest = function(scope) {
        var object = this;

        this.updatingCounter.increase();
        this.client.sendXmlRequest(
            this.uri,
            "<Render Scope=\"" + RenderScope.toText(scope) + "\"/>",
            function(xmlResponse) { object.refreshWithScope(xmlResponse, scope); }
        );
    }

    this.toggleAction = function(action) {
        this.sendCommandRequest("<Action Name=\"" + action + "\"/>");
    }

    this.client = new HttpClient();
    this.updatingCounter = new HtmlClassCounter(this.element, "Updating");

    this.initialize();
    this.determineElements();
    this.attachRefreshHandler();
}

interactivityRegistration.register("ObjectActionComponent", function(element) { return new ObjectActionComponent(element); });
