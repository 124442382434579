class LoadingIndicator {
    constructor() {
        this.element = document.createElement("div");
        this.element.classList.add("LoadingIndicator");
        this.element.appendChild(document.createElement("div"));
        this.element.appendChild(document.createElement("div"));
        this.element.appendChild(document.createElement("div"));
        this.element.appendChild(document.createElement("div"));
        this.element.appendChild(document.createElement("div"));
        this.element.appendChild(document.createElement("div"));
        this.element.appendChild(document.createElement("div"));
        this.element.appendChild(document.createElement("div"));

        this.active = new HtmlClassSwitch(this.element, "Active");
    }

    getStatus() {
        return this.active.getStatus();
    }

    setStatus(active) {
        this.active.setStatus(active);
    }
}
