var ToolbarState = new Enumeration(["None", "Warning", "Error", "Submitted"]);

class Toolbar extends WebPageComponentClass {
    constructor(element) {
        super(element);

        this.state = ToolbarState.None;

        this.warning = new HtmlClassSwitch(this.element, "Warning");
        this.successful = new HtmlClassSwitch(this.element, "Successful");
        this.error = new HtmlClassSwitch(this.element, "Error");

        this.determineElements();
        this.attachHandlers();

        this.element.component = this;
    }

    attachHandlers() {
        connectClickOutsideListener(
            this.element,
            () => { this.successful.setStatus(false); }
        );

        if (this.actionForm !== null)
            this.actionForm.onsubmit = (event) => { return false; };

        for (const group of this.groups)
            new ActionGroup(group);
    }

    createKeyHandler() {
        return (event) => {
            if (event.code === "ArrowLeft") {
                this.focus(this.focusedItemIndex - 1);

                event.stopPropagation();
                event.preventDefault();
            }
            else if (event.code === "ArrowRight") {
                this.focus(this.focusedItemIndex + 1);

                event.stopPropagation();
                event.preventDefault();
            }
            else if (event.code === "Home") {
                this.focus(0);

                event.stopPropagation();
                event.preventDefault();
            }
            else if (event.code === "End") {
                this.focus(this.buttons.length - 1);

                event.stopPropagation();
                event.preventDefault();
            }
        }
    }

    determineElements() {
        let query = new DomQuery(this.element);

        this.groups = query.getDescendants(WithClass("ActionGroup"));
        this.iconBar = query.getChild(WithClass("IconBar"));
        this.actionForm = query.getChild(WithClass("ActionForm"));
        this.actionBar = query.getChild(WithClass("ActionBar"));
        this.actionFeedback = query.getDescendant(WithClass("ActionFeedback"));
        this.actionConfirmation = query.getDescendant(WithClass("ActionConfirmationWindow"));

        query = new DomQuery(this.actionBar)
        this.menu = query.getChild(WithClass("Menu"));
        this.objectActions = query.getDescendants(WithClass("ObjectAction"));

        this.form = new DomQuery(this.actionForm).getChild(WithTagName("FORM"));

        this.initializeButtons();

        if (this.menu)
            this.initializeMenu();
    }

    focus(index) {
        if (index >= 0 && index < this.buttons.length) {
            this.buttons[this.focusedItemIndex].tabIndex = -1;
            this.buttons[index].tabIndex = 0;
            this.buttons[index].focus();

            this.focusedItemIndex = index;
        }
    }

    handleAction(action) {
        const objectAction = this.objectActions.find(element => element.dataset.Name === action);

        if (objectAction !== undefined)
            objectAction.component.open();
        else
            this.parentComponent.sendCommandRequest("<Action Name=\"" + action + "\"/>");
    }

    handleEvent(event) {
        if (event instanceof MenuActionEvent) {
            this.handleAction(event.action);
            event.handled = true;
        }
    }

    hasActionEnabled() {
        return this.objectActions.some((action) => {return action.classList.contains("Active");});
    }

    initializeButtons() {
        this.buttons = new Array();
        const buttons = new DomQuery(this.iconBar).getDescendants((element) => { return element.tagName === "BUTTON" & !element.disabled; });

        for (const button of buttons)
            this.buttons.push(button);

        for (const button of this.buttons) {
            button.tabIndex = -1;
            button.addEventListener("keydown", this.createKeyHandler());
        }

        if (this.buttons.length > 0)
            this.buttons[0].tabIndex = 0;

        this.focusedItemIndex = 0;
    }

    initializeMenu() {
        const query = new DomQuery(this.menu);
        const menuActions = query.getDescendants(WithClass("MenuOnly"));

        if (menuActions.length === 0)
            this.menu.classList.add("Empty");
    }

    replace(toolbar, persistForm) {
        interactivityRegistration.detach(this.iconBar);
        interactivityRegistration.detach(this.actionBar);
        interactivityRegistration.detach(this.actionFeedback);

        const query = new DomQuery(toolbar);
        const iconBar = query.getChild(WithClass("IconBar"));
        const actionBar = query.getChild(WithClass("ActionBar"));
        const actionForm = query.getChild(WithClass("ActionForm"));
        const actionFeedback = query.getChild(WithClass("ActionFeedback"));
        const form = new DomQuery(actionForm).getChild(WithTagName("FORM"));

        this.element.replaceChild(iconBar, this.iconBar);
        this.element.replaceChild(actionBar, this.actionBar);
        this.element.replaceChild(actionFeedback, this.actionFeedback);

        const replaceForm = !persistForm || this.form === null || form === null || this.form.id !== form.id;

        if (replaceForm) {
            interactivityRegistration.detach(this.actionForm)
            this.element.replaceChild(actionForm, this.actionForm);
        }

        this.updateState();
        this.determineElements();
        this.attachHandlers();        

        interactivityRegistration.attach(this.iconBar);
        interactivityRegistration.attach(this.actionBar);
        interactivityRegistration.attach(this.actionFeedback);

        if (replaceForm)
            interactivityRegistration.attach(this.actionForm);
    }

    updateState() {
        this.successful.setStatus(this.state === ToolbarState.Submitted);
        this.warning.setStatus(this.state === ToolbarState.Warning);
        this.error.setStatus(this.state === ToolbarState.Error);

        this.state = ToolbarState.None;
    }
}
