const Orientation = new Enumeration(["Horizontal", "Vertical"]);

function MenuActionEvent(action) {
    this.action = action;
}

function Menu(element) {
    WebPageComponent.call(this, element);

    this.bind = function() {
        if (this.type === "SlideOut") {
            this.screenWidthListener = (event) => {
                if (event.matches && this.element.classList.contains("Expanded"))
                    this.toggleIconizedState(true);
            }

            mediumScreenQuery.addEventListener('change', this.screenWidthListener);
        }
    }

    this.release = function() {
        if (this.screenWidthListener !== undefined)
            mediumScreenQuery.removeEventListener('change', this.screenWidthListener);
    }

    this.updateMenuItem = function(menuItem, command) {
        if (this.uri !== undefined) {
            const itemId = menuItem.getElementsByTagName("span")[0].dataset.Id;
            const commands = "<Commands><" + command + " Item=\"" + escapeXMLAttributeValue(itemId) + "\"/></Commands>";

            fetch(
                this.uri,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/xml'
                    },
                    body: commands
                }
            )
                .then(response => { menuItem.classList.remove("Updating"); })
                .catch(error => { console.error('Error:', error); });
        }
    }

    this.expand = function(element, event) {
        const classes = new HtmlClasses(element);

        if (!classes.contains("expanded")) {
            classes.add("expanded");
            classes.remove("collapsed");

            if (this.uri !== undefined)
                classes.add("Updating");

            this.updateMenuItem(element, "Expand");

            if (this.isTopLevelMenu(element))
                element.outsideListener = connectClickOutsideListener(
                    element,
                    (event) => {
                        this.collapse(element, getEvent(event));
                    }
                );
        }
    }

    this.collapse = function(element, event) {
        const classes = new HtmlClasses(element);

        if (!classes.contains("collapsed")) {
            classes.add("collapsed");
            classes.remove("expanded");

            if (this.uri !== undefined)
                classes.add("Updating");

            this.updateMenuItem(element, "Collapse");

            if (this.isTopLevelMenu(element) && element.outsideListener != null)
                removeClickOutsideListener(element.outsideListener);
        }
    }

    this.attachItemHandlers = function(element, collapsed) {
        const span = new DomQuery(element).getChild(WithTagName("SPAN"));
        const button = new DomQuery(span).getChild(WithTagName("BUTTON"));
        const classes = new HtmlClasses(span);

        classes.add("Collapsible");

        if (span.firstChild.tagName !== "A") {
            span.firstChild.addEventListener(
                'click',
                (event) => {
                    if (element.classList.contains("collapsed"))
                        this.expand(element, getEvent(event));
                    else if (element.classList.contains("expanded"))
                        this.collapse(element, getEvent(event));
                }
            );
        }

        button.addEventListener(
            'click',
            (event) => {
                if (element.classList.contains("collapsed"))
                    this.expand(element, getEvent(event));
                else if (element.classList.contains("expanded"))
                    this.collapse(element, getEvent(event));
            }
        );
    }

    this.attachActionHandlers = function() {
        const actions = new DomQuery(this.element).getDescendants(WithClass('Action'));

        for (const action of actions) {
            action.addEventListener(
                'click',
                (event) => {
                    distributeEventUpHierarchy(new MenuActionEvent(action.dataset.Action), this);
                }
            );
        }
    }

    this.isTopLevelMenu = function(element) {
        const classes = new HtmlClasses(element.parentNode.parentNode);
        return (classes.contains("Menu") && classes.contains("Horizontal"));
    }

    this.attachItemsHandlers = function(elements, collapsed) {
        for (const element of elements)
            this.attachItemHandlers(element, collapsed);
    }

    this.attachSlideOutHandler = function(toolbar, handler) {
        const toggle = new DomQuery(toolbar).getChild(WithClass("Toggle"));
        const classes = new HtmlClasses(toggle);

        classes.add("Collapsible");
        toggle.addEventListener('click', handler);
    }

    this.booleanToAttribute = function(value) {
        if (value)
            return "True";
        else
            return "False";
    }

    this.sendIconizeCommand = function(value) {
        if (this.uri !== undefined) {
            const xmlRequest = newXmlRequest();
            const commands = "<Commands><Iconize" + " Value=\"" + this.booleanToAttribute(value) + "\"/></Commands>";

            xmlRequest.open("POST", this.uri, true);
            xmlRequest.setRequestHeader("Content-Type", "application/xml");
            xmlRequest.send(commands);
        }
    }

    this.sendSlideOutCommand = function(value) {
        if (this.uri !== undefined) {
            const xmlRequest = newXmlRequest();
            const commands = "<Commands><SlideOutLevel" + " Value=\"" + value + "\"/></Commands>";

            xmlRequest.open("POST", this.uri, true);
            xmlRequest.setRequestHeader("Content-Type", "application/xml");
            xmlRequest.send(commands);
        }
    }

    this.toggleIconizedState = function(persist) {
        const classes = new HtmlClasses(this.element);

        if (classes.contains("Expanded")) {
            classes.remove("Expanded");
            classes.add("Collapsed");

            if (persist)
                this.sendIconizeCommand(true);
        }
        else {
            classes.remove("Collapsed");
            classes.add("Expanded");

            if (persist)
                this.sendIconizeCommand(false);
        }

        setTimeout(
            function() {
                distributeEvent(new AvailableSizeChangedEvent());
            },
            500
        );
    }

    this.attachSlideOutHandlers = function() {
        const firstLevelToolbar = new DomQuery(this.element).getChild(WithClass("Toolbar"));

        this.attachSlideOutHandler(
            firstLevelToolbar,
            (event) => {
                this.toggleIconizedState(!isSmallScreen());
            }
        );

        if (mediumScreenQuery.matches && this.element.classList.contains("Expanded"))
            this.toggleIconizedState(true);

        const secondLevelMenu = new DomQuery(this.element).getChild(WithTagName("OL"));
        const menuItems = new DomQuery(secondLevelMenu).getChildren(WithTagName("LI"));

        for (const menuItem of menuItems) {
            const toolbar = new DomQuery(menuItem).getDescendant(WithClass("Toolbar"));

            if (toolbar !== null) {
                if (new HtmlClasses(menuItem).contains("pathCurrent"))
                    this.itemHasToolbar.setStatus(true);

                this.attachSlideOutHandler(
                    toolbar,
                    (event) => {
                        if (this.getSlideOutLevel() === "2")
                            this.setSlideOutLevel("1");
                        else
                            this.setSlideOutLevel("2");

                        this.sendSlideOutCommand(this.getSlideOutLevel());

                        setTimeout(
                            function() {
                                distributeEvent(new AvailableSizeChangedEvent());
                            },
                            500
                        );
                    }
                );
            };
        }

        const plusLevelMenus = new DomQuery(secondLevelMenu).getDescendants(WithTagName("OL"));

        for (const plusLevelMenu of plusLevelMenus) {
            this.attachItemsHandlers(new DomQuery(plusLevelMenu).getChildren(WithClass("collapsed")), true);
            this.attachItemsHandlers(new DomQuery(plusLevelMenu).getChildren(WithClass("expanded")), false);
        }
    }

    this.getSlideOutLevel = function() {
        return this.element.getAttribute("data-slide-out-level");
    }

    this.setSlideOutLevel = function(level) {
        this.element.setAttribute("data-slide-out-level", level);
    }

    this.determineElements = function() {
        const query = new DomQuery(this.element);

        if (this.type === "SlideOut")
            this.attachSlideOutHandlers();
        else if (this.type !== "SlideOut") {
            this.attachItemsHandlers(query.getDescendants(WithClass("collapsed")), true);
            this.attachItemsHandlers(query.getDescendants(WithClass("expanded")), false);
        }
    }

    this.attachKeyboardInteractivity = function() {
        this.element.addEventListener(
            "keydown",
            (event) => {
                switch (event.code) {
                    case "ArrowLeft":
                        if (this.orientation === Orientation.Horizontal && this.focusedItem.parent === this.item)
                            this.focusPreviousItem();
                        else
                            this.focusParentItem();

                        event.preventDefault();
                        break;
                    case "ArrowRight":
                        if (this.orientation === Orientation.Horizontal && this.focusedItem.parent === this.item)
                            this.focusNextItem();
                        else
                            this.focusChildItem();

                        event.preventDefault();
                        break;
                    case "ArrowUp":
                        if (this.orientation === Orientation.Horizontal && this.focusedItem.parent === this.item)
                            this.focusParentItem();
                        else
                            this.focusPreviousItem();

                        event.preventDefault();
                        break;
                    case "ArrowDown":
                        if (this.orientation === Orientation.Horizontal && this.focusedItem.parent === this.item)
                            this.focusChildItem();
                        else
                            this.focusNextItem();

                        event.preventDefault();
                        break;
                    case "Space":
                        this.focusChildItem();
                        event.preventDefault();
                        break;
                    case "Escape":
                        this.focusParentItem();
                        event.preventDefault();
                        break;
                }
            }
        );
    }

    this.focus = function(item) {
        if (item !== null) {
            this.focusedItem = item;

            if (this.focusedItem.link !== null)
                this.focusedItem.link.focus();
        }
    }

    this.focusChildItem = function() {
        if (this.focusedItem.items.length > 0) {
            if (this.focusedItem.isExpanded())
                this.focus(this.focusedItem.getChild());
            else if (this.focusedItem.expandable)
                this.expand(this.focusedItem.element);
        }
        else if (this.focusedItem.parent !== this.item)
            this.focus(this.focusedItem.parent.getNextSibling());
    }

    this.focusNextItem = function() {
        this.focus(this.focusedItem.getNextSibling());
    }

    this.focusParentItem = function() {
        if (this.focusedItem.expandable && this.focusedItem.isExpanded())
            this.collapse(this.focusedItem.element);
        else if (this.focusedItem.parent !== this.item)
            this.focus(this.focusedItem.parent);
    }

    this.focusPreviousItem = function() {
        this.focus(this.focusedItem.getPreviousSibling());
    }

    this.initialize = function() {
        if (this.element.classList.contains("Horizontal"))
            this.orientation = Orientation.Horizontal;
        else if (this.element.classList.contains("Vertical"))
            this.orientation = Orientation.Vertical;
        else
            throw new Error("Unspecified menu orientation");

        this.item = new MenuItem(this.element, null);

        if (this.item.items.length > 0) {
            this.item.items[0].setFocusable(this);
            this.focusedItem = this.item.items[0];
        }
    }

    this.uri = this.element.dataset.Uri;
    this.type = this.element.dataset.Type;
    this.itemHasToolbar = new HtmlClassSwitch(element, "ItemHasToolbar");
    this.determineElements();
    this.attachActionHandlers();

    this.initialize();
    this.attachKeyboardInteractivity();
}

function MenuItem(element, parent) {
    this.getChild = function() {
        if (this.items.length > 0)
            return this.items[0];
    }

    this.getNextSibling = function() {
        if (this.parent !== null) {
            const index = this.parent.items.indexOf(this);
            const nextIndex = (index + 1) % this.parent.items.length;

            return this.parent.items[nextIndex];
        }
        else
            return null;
    }

    this.getPreviousSibling = function() {
        if (this.parent !== null) {
            const index = this.parent.items.indexOf(this);
            let previousIndex = (index - 1) % this.parent.items.length;

            if (previousIndex < 0)
                previousIndex = previousIndex + this.parent.items.length;

            return this.parent.items[previousIndex];
        }
        else
            return null;
    }

    this.initialize = function() {
        this.label = new DomQuery(this.element).getChild(WithTagName("SPAN"));

        if (this.label !== null) {
            this.link = this.label.childNodes[0];
            this.link.tabIndex = -1;

            this.expandable = this.label.classList.contains("Collapsible");
        }
        else
            this.expandable = false;

        const list = new DomQuery(this.element).getChild(WithTagName("OL"));

        if (list !== null) {
            const elements = new DomQuery(list).getChildren(WithTagName("LI"));

            for (const element of elements)
                this.items.push(new MenuItem(element, this));
        }
    }

    this.isExpanded = function() {
        return this.element.classList.contains("expanded");
    }

    this.setFocusable = function(component) {
        if (this.link !== null) {
            this.link.tabIndex = 0;
            this.link.addEventListener(
                "focus",
                (event) => {
                    component.focusedItem = this;
                }
            );
        }
    }

    this.element = element;
    this.parent = parent;
    this.items = new Array();
    this.initialize();
}

interactivityRegistration.register(
    "Menu",
    function(element) {
        if (element.tagName === "DIV")
            return new Menu(element);
        else
            return null;
    }
);
