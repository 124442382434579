function ConfirmationWindow(element) {
    WebPageComponent.call(this, element);

    this.determineElements = function() {
        const query = new DomQuery(this.element);

        this.submit = query.getDescendant(WithClass("Submit"));
        this.cancel = query.getDescendant(WithClass("Cancel"));
        this.parent = element.parentNode;
    }

    this.show = function(target, onSuccess) {
        target.appendChild(this.element);

        this.submit.addEventListener(
            "click",
            (event) => {
                onSuccess();
                this.parent.appendChild(this.element);
            }
        );

        this.cancel.addEventListener(
            "click",
            (event) => {
                this.parent.appendChild(this.element);
            }
        );
    }

    this.determineElements();
}

function ObjectAction(element) {
    WebPageComponent.call(this, element);

    this.canSubmit = true; // TODO: We should properly disable any other action while any operation is still in progress

    this.attachEventHandlers = function() {
        var object = this;

        if (this.caption !== null)
            this.caption.addEventListener("click", function(event) { object.open(); });

        if (this.submit !== null)
            this.submit.addEventListener("click", function(event) { object.submitForm() });

        if (this.cancel !== null)
            this.cancel.addEventListener("click", function(event) { object.cancelForm() });
    }

    this.bind = function() {
        const form = this.getForm();

        if (form !== null) {
            form.addEventListener(
                "keydown",
                this.keyListener
            );

            if (form.component !== undefined)
                form.component.focus();
        }
    }

    this.release = function() {
        const form = this.getForm();

        if (form !== null) {
            form.removeEventListener(
                "keydown",
                this.keyListener
            );
        }
    }

    this.cancelForm = function() {
        const parent = this.parentComponent.parentComponent;
        parent.sendCommandRequest("<Action Name=\"\"/>");
    }

    this.determineElements = function() {
        const query = new DomQuery(this.element);

        this.caption = query.getChild(WithClass("Caption"));
        this.submit = query.getChild(WithClass("Submit"));
        this.cancel = query.getChild(WithClass("Cancel"));

        if (this.requiresConfirmationWindow)
            this.confirmationWindow = new ConfirmationWindow(query.getChild(WithClass("Confirmation")));
    }

    this.execute = function() {
        const parent = this.parentComponent.parentComponent;
        const form = this.getForm();

        parent.sendActionRequest(this.name, form, () => this.canSubmit = true);
    }

    this.getForm = function() {
        if (this.useSiblingComponent)
            return this.parentComponent.parentComponent.getForm();
        else if (this.parentComponent.actionForm !== null)
            return new DomQuery(this.parentComponent.actionForm).getDescendant(WithTagName("FORM"));
    }

    this.handleHotKey = function(event) {
        if (event.ctrlKey && event.key === "Enter") {
            this.submitForm();
            event.preventDefault();
        }
        else if (event.key === "Escape") {
            this.cancelForm();
        }
    }

    this.open = function() {
        if (!this.requiresConfirmation) {
            if (this.requiresConfirmationWindow)
                this.showConfirmationWindow();
            else
                this.execute();
        }
        else
            this.showForm();
    }

    this.submitForm = function() {
        if (this.canSubmit) {
            this.canSubmit = false;

            if (this.requiresConfirmationWindow) {
                this.showConfirmationWindow();
                this.canSubmit = true;
            }
            else
                this.execute();
        }
    }

    this.showConfirmationWindow = function() {
        this.confirmationWindow.show(
            this.parentComponent.actionConfirmation,
            () => {
                this.execute();
            }
        );
    }

    this.showForm = function() {
        const parent = this.parentComponent.parentComponent;
        parent.toggleAction(this.name);
    }

    this.name = element.dataset.Name;
    this.uri = element.dataset.Uri;
    this.requiresConfirmation = element.dataset.RequiresConfirmation === "true";
    this.requiresConfirmationWindow = element.dataset.ConfirmationWindow === "true";
    this.useSiblingComponent = element.dataset.UseSiblingComponent === "true";

    this.keyListener = (event) => { this.handleHotKey(getEvent(event)); }
    this.determineElements();

    if (!this.element.classList.contains("Disabled"))
        this.attachEventHandlers();
}

function ActionGroup(element) {
    this.initialize = function() {
        this.collapsed = this.element.dataset.Collapsed === "true";
        this.icon = new DomQuery(this.element).getChild(WithClass("Icon"));
        this.caption = new DomQuery(this.element).getChild(WithClass("Caption"));

        if (this.collapsed) {
            this.collapsed = new HtmlClassSwitch(this.element, "Collapsed");

            var object = this;
            this.icon.onclick = function(event) {
                object.collapsed.toggle();
                var listener = connectClickOutsideListener(object.element, function(event) { object.collapsed.toggle(); removeClickOutsideListener(listener); });
            };

            this.caption.onclick = function(event) {
                object.collapsed.toggle();
                var listener = connectClickOutsideListener(object.element, function(event) { object.collapsed.toggle(); removeClickOutsideListener(listener); });
            };
        }
    }

    this.element = element;
    this.initialize();
}

interactivityRegistration.register("ObjectAction", function(element) { return new ObjectAction(element); });
