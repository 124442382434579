class SynchronizationCenter {
  constructor() {
    this.entityStore = new EntityStore();
    this.initialize();
  }

  connect(serviceWorker) {
    if (this.listener !== undefined)
      this.serviceWorker.removeEventListener("message", this.listener);

    this.listener = (event) => {
      const data = event.data;

      if (event.data.class === "Entities")
        this.reloadAll(data.value);
    }

    this.serviceWorker = serviceWorker;
    this.serviceWorker.addEventListener("message", this.listener);
  }

  initialize() {
    this.button = document.getElementsByClassName("Synchronization")[0];
    this.button.addEventListener(
      "click",
      (event) => {
        this.setApplicationStatus(true);
      }
    );

    this.offline = new HtmlClassSwitch(this.button, "Offline");
  }

  reloadAll(entities) {
    this.setApplicationStatus(entities.status);
  }

  setApplicationStatus(online) {
    application.online = online;
    this.offline.setStatus(!online);
  }
}
