class EntityStore {
  constructor() {
    this.databaseName = "EntityStore"
    this.entityStoreName = "entities";

    this.database = null;

    const version = 1;
    const request = indexedDB.open(this.databaseName, version);

    request.onsuccess = (event) => {
      this.database = event.target.result;
    }

    request.onupgradeneeded = (event) => {
      const database = event.target.result;
      database.createObjectStore(this.entityStoreName, { keyPath: "Uri" });
    }
  }

  getEntityStore(transaction) {
    return transaction.objectStore(this.entityStoreName);
  }

  add(entity) {
    return new Promise((resolve, reject) => {
      let result = entity;

      const transaction = this.database.transaction([this.entityStoreName], "readwrite");
      const entities = this.getEntityStore(transaction);

      transaction.oncomplete = () => {
        resolve(result);
      }

      transaction.onerror = (event) => {
        reject(event.target.error);
      }

      entities.put(entity);
    });
  }

  delete(key) {
    return new Promise((resolve, reject) => {
      const transaction = this.database.transaction([this.entityStoreName], "readwrite");

      transaction.oncomplete = () => {
        resolve();
      }

      transaction.onerror = (event) => {
        reject(event.target.error);
      }

      const entities = this.getEntityStore(transaction);
      entities.delete(key);
    });
  }

  deleteMatches(indexName, value) {
    return new Promise((resolve, reject) => {
      const transaction = this.database.transaction([this.entityStoreName], "readwrite");
      const entities = this.getEntityStore(transaction);
      const index = entities.index(indexName);
      const range = IDBKeyRange.only(value);

      transaction.oncomplete = () => {
        resolve();
      }

      transaction.onerror = (event) => {
        reject(event.target.error);
      }

      const request = index.openCursor(range);

      request.onsuccess = () => {
        const cursor = request.result;

        if (cursor) {
          cursor.delete();
          cursor.continue();
        }
      }
    });
  }

  get(key) {
    return new Promise((resolve, reject) => {
      let result;

      const transaction = this.database.transaction(this.entityStoreName, "readonly");
      const entities = this.getEntityStore(transaction);

      transaction.oncomplete = () => {
        resolve(result);
      }

      transaction.onerror = (event) => {
        reject(event.target.error);
      }

      const request = entities.get(key);
      request.onsuccess = () => {
        result = request.result;
      }
    });
  }

  getAll() {
    return new Promise((resolve, reject) => {
      let result;

      const transaction = this.database.transaction(this.entityStoreName, "readonly");
      const entities = this.getEntityStore(transaction);

      transaction.oncomplete = () => {
        resolve(result);
      }

      transaction.onerror = (event) => {
        reject(event.target.error);
      }

      const request = entities.getAll();
      request.onsuccess = () => {
        result = request.result;
      }
    });
  }

  update(entity) {
    return new Promise((resolve, reject) => {
      const transaction = this.database.transaction(this.entityStoreName, "readwrite");
      const entities = this.getEntityStore(transaction);

      transaction.oncomplete = () => {
        resolve();
      }

      transaction.onerror = (event) => {
        reject(event.target.error);
      }

      entities.put(
        entity
      );
    });
  }
}
