const State = new Enumeration(["Initial", "Preparing", "Prepared", "Uploading", "Error", "Successful", "Aborted", "Forbidden"]);

class FileStore {
  constructor() {
    this.databaseName = "FileStore"
    this.fileStoreName = "files";
    this.fileContentsStoreName = "file_contents";

    this.database = null;

    const version = 2;
    const request = indexedDB.open(this.databaseName, version);

    request.onsuccess = (event) => {
      this.database = event.target.result;
    }

    request.onupgradeneeded = (event) => {
      const database = event.target.result;

      if (event.oldVersion < 1) {
        const fileStore = database.createObjectStore(this.fileStoreName, { keyPath: "id", autoIncrement: true });
        fileStore.createIndex("name", "name", { unique: false });
        fileStore.createIndex("state", "state", { unique: false });
        fileStore.createIndex("target", "target", { unique: false });
      }
      
      if (event.oldVersion < 2) {
        database.createObjectStore(this.fileContentsStoreName, { keyPath: "id" });

        const files = this.getFileStore(event.target.transaction);

        files.openCursor().onsuccess = (event) => {
          const cursor = event.target.result;

          if (cursor) {
            const file = cursor.value;

            const contents = this.getFileContentStore(event.target.transaction);
            contents.add({
              id: file.id,
              contents: file.contents
            })

            file.contents = undefined;

            cursor.update(file);
            cursor.continue();
          }
        }
      }
    }
  }

  getFileStore(transaction) {
    return transaction.objectStore(this.fileStoreName);
  }

  getFileContentStore(transaction) {
    return transaction.objectStore(this.fileContentsStoreName);
  }

  add(file) {
    return new Promise((resolve, reject) => {
      let result = file;

      const transaction = this.database.transaction([this.fileStoreName, this.fileContentsStoreName], "readwrite");
      const files = this.getFileStore(transaction);

      transaction.oncomplete = () => {
        resolve(result);
      }

      transaction.onerror = (event) => {
        reject(event.target.error);
      }

      const request = files.add({
        target: file.target,
        name: file.name,
        size: file.size,
        state: file.state,
        attempts: file.attempts,
        progress: file.progress,
        message: file.message,
        location: file.location
      });
      request.onsuccess = () => {
        file.id = request.result;

        const contents = this.getFileContentStore(transaction);
        contents.add({
          id: file.id,
          contents: file.contents
        });  
      }
    });
  }

  delete(key) {
    return new Promise((resolve, reject) => {
      const transaction = this.database.transaction([this.fileStoreName, this.fileContentsStoreName], "readwrite");

      transaction.oncomplete = () => {
        resolve();
      }

      transaction.onerror = (event) => {
        reject(event.target.error);
      }

      const files = this.getFileStore(transaction);
      files.delete(key);

      const contents = this.getFileContentStore(transaction);
      contents.delete(key);
    });
  }

  deleteMatches(indexName, value) {
    return new Promise((resolve, reject) => {
      const transaction = this.database.transaction([this.fileStoreName, this.fileContentsStoreName], "readwrite");
      const files = this.getFileStore(transaction);
      const index = files.index(indexName);
      const range = IDBKeyRange.only(value);

      transaction.oncomplete = () => {
        resolve();
      }

      transaction.onerror = (event) => {
        reject(event.target.error);
      }

      const request = index.openCursor(range);

      request.onsuccess = () => {
        const cursor = request.result;

        if (cursor) {
          const contents = this.getFileContentStore(transaction);
          contents.delete(cursor.value.id);

          cursor.delete();
          cursor.continue();
        }
      }
    });
  }

  getFileContents(key) {
    return new Promise((resolve, reject) => {
      let result;

      const transaction = this.database.transaction(this.fileContentsStoreName, "readonly");
      const contents = this.getFileContentStore(transaction);

      transaction.oncomplete = () => {
        resolve(result);
      }

      transaction.onerror = (event) => {
        reject(event.target.error);
      }

      const request = contents.get(key);
      request.onsuccess = () => {
        result = request.result.contents;
      }
    });
  }

  get(key) {
    return new Promise((resolve, reject) => {
      let result;

      const transaction = this.database.transaction(this.fileStoreName, "readonly");
      const files = this.getFileStore(transaction);

      transaction.oncomplete = () => {
        resolve(result);
      }

      transaction.onerror = (event) => {
        reject(event.target.error);
      }

      const request = files.get(key);
      request.onsuccess = () => {
        result = request.result;
      }
    });
  }

  getAll() {
    return new Promise((resolve, reject) => {
      let result;

      const transaction = this.database.transaction(this.fileStoreName, "readonly");
      const files = this.getFileStore(transaction);

      transaction.oncomplete = () => {
        resolve(result);
      }

      transaction.onerror = (event) => {
        reject(event.target.error);
      }

      const request = files.getAll();
      request.onsuccess = () => {
        result = request.result;
      }
    });
  }

  update(file) {
    return new Promise((resolve, reject) => {
      const transaction = this.database.transaction(this.fileStoreName, "readwrite");
      const files = this.getFileStore(transaction);

      transaction.oncomplete = () => {
        resolve();
      }

      transaction.onerror = (event) => {
        reject(event.target.error);
      }

      files.put({
        id: file.id,
        target: file.target,
        name: file.name,
        size: file.size,
        state: file.state,
        attempts: file.attempts,
        progress: file.progress,
        message: file.message,
        location: file.location
      });
    });
  }
}
