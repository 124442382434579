function GridPanel(element) {
    WebPageComponent.call(this, element);

    this.initialize = function() {
        this.bounds = new Rectangle(
            element.dataset.Left,
            element.dataset.Top,
            element.dataset.Right,
            element.dataset.Bottom
        );

        this.element.style.gridTemplateRows = "repeat(" + this.bounds.height() + ", auto)";
        this.element.style.gridTemplateColumns = "repeat(" + this.bounds.width() + ", minmax(0, 1fr))";

        this.components = new DomQuery(this.element).getChildren(WithClass("GridItem"));

        for (let index = 0; index < this.components.length; index++) {
            const component = this.components[index];

            component.bounds = new Rectangle(
                parseInt(component.dataset.Left) + 1,
                parseInt(component.dataset.Top) + 1,
                parseInt(component.dataset.Right) + 1,
                parseInt(component.dataset.Bottom) + 1
            );

            component.style.gridColumn = component.bounds.left;
            component.style.gridColumnEnd = component.bounds.right;
            component.style.gridRow = component.bounds.top;
            component.style.gridRowEnd = component.bounds.bottom;
        }

        for (let index = 0; index < this.components.length; index++) {
            const component = this.components[index];

            component.style.visibility = "visible";

            if (component.childNodes.length === 2) {
                if (component.childNodes[1].component !== undefined)
                    component.childNodes[1].component.handleEvent(new VisibilityChangedEvent(this));
            }
        }
    }

    this.initialize();
}

function Rectangle(left, top, right, bottom) {
    this.left = left;
    this.top = top;
    this.right = right;
    this.bottom = bottom;

    this.height = function() {
        return (this.bottom - this.top + 1);
    }

    this.width = function() {
        return (this.right - this.left + 1);
    }
}

interactivityRegistration.register("Grid", function(element) { return new GridPanel(element); });
