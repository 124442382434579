class SearchComponent extends WebPageComponentClass {
  constructor(element) {
    super(element);

    this.uri = this.element.dataset.Uri;
    this.active = new HtmlClassSwitch(this.element, "Active");
    this.input = new DomQuery(this.element).getDescendant(WithClass("Field")).component;
    this.results = new DomQuery(this.element).getDescendant(WithTagName("UL"));
    this.loading = new HtmlClassSwitch(this.element, "Loading");
    this.searchTimer = new Timer(400);
    this.searchResults = new Array();

    this.searchButton = new DomQuery(document.documentElement).getDescendant(WithClass("SearchButton"));

    if (this.searchButton !== undefined)
      this.searchButton.addEventListener(
        "click",
        (event) => {
          this.open();
        }
      );

    this.loadingIndicator = new LoadingIndicator();
    this.element.childNodes[0].appendChild(this.loadingIndicator.element);

    window.addEventListener(
      "keydown",
      (event) => {
        if (event.target.tagName !== "INPUT" && event.target.tagName !== "TEXTAREA" && !this.active.getStatus() && event.key === "/") {
          this.open();

          event.preventDefault();
          event.stopPropagation();
        }
      }
    );

    this.element.addEventListener(
      "keydown",
      (event) => {
        if (this.active.getStatus() && event.key === "Escape") {
          this.close();

          event.preventDefault();
          event.stopPropagation();
        }
      }
    );

    this.input.addEventListener(
      "keydown",
      (event) => {
        if (event.key === "ArrowDown" && this.results.childNodes.length > 0) {
          this.results.firstChild.focus();
          event.preventDefault();
        }
      }
    );

    this.input.addEventListener(
      "input",
      (event) => {
        this.scheduleSearchOperation();
      }
    );
  }

  scheduleSearchOperation() {
    this.searchTimer.kill();
    this.searchTimer.schedule(
      () => {
        if (!this.loading.getStatus() && this.input.getValue().length > 2)
          this.search(this.input.getValue());
        else if (this.input.getValue().length > 0)
          this.clear();
        else
          this.prepare();
      }
    )
  }

  clear() {
    this.results.innerHTML = "";
    this.searchResults = new Array();
  }

  close() {
    this.input.input.blur();
    this.active.setStatus(false);
    removeClickOutsideListener(this.clickOutsideListener);

    this.input.setValue("");
    this.clear();
  }

  open() {
    this.active.setStatus(true);
    this.prepare();

    setTimeout(
      () => {
        this.input.focus();
      },
      500
    );

    this.clickOutsideListener = connectClickOutsideListener(
      this.element.childNodes[0],
      () => {
        this.close();
      }
    );
  }

  prepare() {
    this.clear();

    this.loading.setStatus(true);
    this.loadingIndicator.setStatus(true);

    application.synchronizationCenter.entityStore.getAll()
    .then((data) => {
      this.renderResults("", data)
    })
    .finally(() => {        
      this.loading.setStatus(false);
      this.loadingIndicator.setStatus(false);
    });
  }

  renderResults(query, results) {
    for (const result of results) {
      if (!this.searchResults.includes(result.Uri)) {
        this.searchResults.push(result.Uri);
        
        const regularExpression = new RegExp(query, "gi");

        const title = document.createElement("span");
        title.classList.add("Title");
        title.innerHTML = result.Title.replace(regularExpression, "<strong>$&</strong>");

        let details = null;

        if (result.Details.length > 0) {
          details = document.createElement("span");
          details.classList.add("Details");
          details.innerHTML = result.Details.replace(regularExpression, "<strong>$&</strong>");
        }

        const uri = document.createElement("span");
        uri.classList.add("Uri");
        uri.innerText = new URL(result.Uri).pathname;

        const resultElement = document.createElement("span");
        resultElement.appendChild(title);

        if (details !== null)
          resultElement.appendChild(details);

        resultElement.appendChild(uri);

        const link = document.createElement("a");
        link.appendChild(resultElement);
        link.href = result.Uri;

        link.addEventListener(
          "click",
          (event) => {
            this.close();
          }
        );

        const item = document.createElement("li");
        item.appendChild(link);
        item.tabIndex = "0";
        item.classList.add(result.Type);

        this.results.appendChild(item);

        item.addEventListener(
          "keydown",
          (event) => {
            if (event.key === "ArrowUp" && item.previousSibling) {
              item.previousSibling.focus();
              event.preventDefault();
            }
            else if (event.key === "ArrowDown" && item.nextSibling) {
              item.nextSibling.focus();
              event.preventDefault();
            }
            else if (event.key === "Enter") {
              item.firstChild.click();
              event.preventDefault();
            }
          }
        )
      }
    }
  }

  search(query) {
    this.loading.setStatus(true);
    this.loadingIndicator.setStatus(true);

    this.clear();
    
    fetch(
      this.uri,
      {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          Query: query
        })
      }
    )
      .then(response => response.json())
      .then(data => {
        this.renderResults(query, data);
      })
      .catch(error => {})
      .then(() => application.synchronizationCenter.entityStore.getAll())
      .then((data) => {
        this.renderResults(query, data.filter((element) => element.Title.toLowerCase().includes(query.toLowerCase()) || element.Details.toLowerCase().includes(query.toLowerCase())))
      })
      .finally(() => {        
        this.loading.setStatus(false);
        this.loadingIndicator.setStatus(false);
      });
  }
}

interactivityRegistration.register("SearchComponent", function(element) { return new SearchComponent(element); });
