class NavigationEvent {
    constructor(uri) {
        this.uri = uri;
    }
}

class PageHandler {
    constructor(rootUri) {
        this.rootUri = rootUri;
        this.initialize();
        this.addListeners();

        this.entityStore = new EntityStore();

        window.history.replaceState(
            { url: window.location.href },
            null,
            window.location.href
        );
    }

    addListeners() {
        addEventListener(
            "popstate",
            (event) => {
                if (event.state) {
                    this.reload(window.location.href);
                    event.preventDefault();
                }
            }
        );

        window.addEventListener(
            "click",
            (event) => {
                const link = event.target.closest("a");

                if (link && !event.ctrlKey && link.target !== "_blank" && link.href.startsWith(this.rootUri)) {
                    event.preventDefault();
                    this.load(link.href);
                }
            }
        );
    }

    build() {
        this.loadingIndicator = new LoadingIndicator();
        this.loading = new HtmlClassSwitch(this.element, "Loading");

        this.loadingPage = document.createElement("div");
        this.loadingPage.classList.add("LoadingPage");
        this.loadingPage.appendChild(this.loadingIndicator.element);

        this.element.appendChild(this.loadingPage);
    }

    initialize() {
        this.element = document.getElementById("main");
        const query = new DomQuery(this.element);

        this.menu = query.getChild(WithClass("Menu"));
        this.breadcrumbs = query.getChild(WithClass("Breadcrumbs"));
        this.contents = query.getChild(WithTagName("MAIN"));
        this.toolbar = query.getChild(WithId("toolbar"));
        this.search = query.getChild(WithClass("SearchComponent"));

        this.build();
    }

    handleDocument(url, text) {
        const newDocument = new DOMParser().parseFromString(text, "text/html");

        if (this.loadSupported() && !this.reloadRequired(newDocument)) {
            const main = new DomQuery(newDocument).getDescendant(WithId("main"));
            const query = new DomQuery(main);

            const menu = query.getChild(WithClass("Menu"));
            const breadcrumbs = query.getChild(WithClass("Breadcrumbs"));
            const contents = query.getChild(WithTagName("MAIN"));
            const toolbar = query.getChild(WithId("toolbar"));
            const search = query.getChild(WithClass("SearchComponent"));

            if (menu !== null && breadcrumbs !== null && contents !== null && toolbar !== null && search !== null) {
                document.title = newDocument.title;
                document.body.dataset.Source = newDocument.body.dataset.Source;

                interactivityRegistration.detach(this.menu);
                interactivityRegistration.detach(this.breadcrumbs);
                interactivityRegistration.detach(this.contents);
                interactivityRegistration.detach(this.toolbar);
                interactivityRegistration.detach(this.search);

                this.element.replaceChild(menu, this.menu);
                this.element.replaceChild(breadcrumbs, this.breadcrumbs);
                this.element.replaceChild(contents, this.contents);
                this.element.replaceChild(toolbar, this.toolbar);
                this.element.replaceChild(search, this.search);

                this.menu = menu;
                this.breadcrumbs = breadcrumbs;
                this.contents = contents;
                this.toolbar = toolbar;
                this.search = search;

                interactivityRegistration.attach(menu);
                interactivityRegistration.attach(breadcrumbs);
                interactivityRegistration.attach(contents);
                interactivityRegistration.attach(toolbar);
                interactivityRegistration.attach(search);

                application.synchronizationCenter.initialize();
                distributeEvent(new NavigationEvent(url));
            }
            else
                this.reloadDocument(newDocument);
        }
        else
            this.reloadDocument(newDocument);
    }

    reloadDocument(newDocument) {
        interactivityRegistration.detach(document.documentElement);
        document.documentElement.innerHTML = newDocument.documentElement.innerHTML;
        interactivityRegistration.attach(document.documentElement);

        application.initialize();
    }

    async fallbackToOfflinePage(uri, error) {
        const entity = await this.entityStore.get(uri);

        if (entity !== undefined) {
            const renderer = new TemplateRenderer();
            const contents = renderer.renderTemplate(entity.Template, entity.Value);

            const breadcrumbs = this.breadcrumbs.cloneNode(true);
            breadcrumbs.firstChild.innerHTML = "";

            const link = document.createElement("a");
            link.href = entity.Uri;
            link.innerText = entity.Title;

            const item = document.createElement("li");
            item.appendChild(document.createElement("span"));
            item.firstChild.appendChild(link);

            const list = document.createElement("ol");
            list.appendChild(item);

            breadcrumbs.firstChild.appendChild(list);

            interactivityRegistration.detach(this.contents);
            interactivityRegistration.detach(this.breadcrumbs);

            this.element.replaceChild(contents, this.contents);
            this.element.replaceChild(breadcrumbs, this.breadcrumbs);

            this.contents = contents;
            this.breadcrumbs = breadcrumbs;

            interactivityRegistration.attach(this.contents);
            interactivityRegistration.attach(this.breadcrumbs);

            window.history.pushState(
                { url: uri },
                null,
                uri
            );
        }
        else {
            application.toastBox.addMessage(new ToastMessage("Failed to load page", error, "Error"));
            console.error(error);
        }
    }

    fetch(url) {
        if (application.online)
            return fetch(
                url,
                {
                    method: "GET",
                    headers: {
                        "Accept": "text/html"
                    }
                }
            );
        else
            return Promise.reject(new Error("The application is offline"));
    }

    load(url) {
        const currentUrl = new URL(window.location.href);
        const navigationUrl = new URL(url);

        if (!this.loadingIndicator.getStatus()) {
            if (navigationUrl.pathname === currentUrl.pathname) {
                if (navigationUrl.hash.length > 0 || navigationUrl.search.length > 0)
                    window.location = navigationUrl;
            }
            else {
                this.setStatus(true);

                this.fetch(url)
                    .then(response => {
                        window.history.pushState(
                            { url: response.url },
                            null,
                            response.url
                        );

                        return response.text();
                    })
                    .then(text => this.handleDocument(url, text))
                    .catch(error => this.fallbackToOfflinePage(url, error))
                    .finally(() => this.setStatus(false));
            }
        }
    }

    setStatus(loading) {
        this.loading.setStatus(loading);
        this.loadingIndicator.setStatus(loading);
    }

    reload(url) {
        this.setStatus(true);

        this.fetch(url)
            .then(async response => {
                if (response.ok)
                    this.handleDocument(url, await response.text());
            })
            .catch(error => this.fallbackToOfflinePage(url, error))
            .finally(() => this.setStatus(false));
    }

    reloadRequired(newDocument) {
        if (newDocument.head.childNodes.length === document.head.childNodes.length) {
            for (let index = 0; index < newDocument.head.childNodes.length; index++) {
                const left = newDocument.head.childNodes[index];
                const right = document.head.childNodes[index];

                if (left.tagName !== "TITLE" && !left.isEqualNode(right)) {
                    return true;
                }
            }

            return false;
        }
        else
            return true;
    }

    loadSupported() {
        return this.menu !== null && this.breadcrumbs !== null && this.contents !== null && this.toolbar !== null;
    }
}
