class Application {
    constructor() {
        this.rootUri = new URL("..", document.scripts[0].src);

        if ('serviceWorker' in navigator) {
            this.uploadCenter = new UploadCenter();
            this.synchronizationCenter = new SynchronizationCenter();

            this.startServiceWorker();

            setInterval(
                () => {
                    this.assureServiceWorkerRunning();
                },
                5000
            );
        }

        this.pageHandler = new PageHandler(this.rootUri);
        this.toastBox = new ToastBox();
        this.online = true;
    }

    initialize() {
        if (this.uploadCenter) {
            this.uploadCenter.initialize();
            this.synchronizationCenter.initialize();
        }

        this.pageHandler.initialize();
        this.toastBox.initialize();
    }

    async assureServiceWorkerRunning() {
        const serviceWorker = await navigator.serviceWorker.getRegistration();

        if (serviceWorker === undefined || serviceWorker.active === null)
            this.startServiceWorker();
        else
            serviceWorker.active.postMessage([]);
    }

    startServiceWorker() {
        const scope = this.rootUri.toString() + "ServiceWorker.js";

        navigator.serviceWorker.addEventListener('controllerchange', (event) => this.uploadCenter.connect(navigator.serviceWorker));
        navigator.serviceWorker.addEventListener('controllerchange', (event) => this.synchronizationCenter.connect(navigator.serviceWorker));
        navigator.serviceWorker.register(scope)
            .then(() => this.uploadCenter.connect(navigator.serviceWorker))
            .then(() => this.synchronizationCenter.connect(navigator.serviceWorker));
    }
}

let application;

window.onload = () => {
    document.documentElement.component = new WebPageComponent(document.documentElement);

    interactivityRegistration.attach(document.body);
    application = new Application();
}
